.action-button-wrapper{
  width: 500px;
}
.action-button{
  border: 5px solid #fff;
  border-radius: 15px;
  background-color: #fff;
  height: 500px;
  width: 500px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.action-button-disabled{
  pointer-events: none;
  opacity: 0.7;
}
.action-button:active{
  transform: scale(1.1);
}
.action-button-text{
  margin-top: 0px;
  font-size: 3em;
  padding: 20px;
  color: #00A2CE;
  background-color: transparent;
  width: 500px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  line-height: 0em;
}
.action-button-icon{
  height: 300px;
  width: auto;
}
