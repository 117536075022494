.help-text{
  color: #fff;
  font-size: 2em;
}
.help-text-big{
  color: #fff;
  font-size: 2.8em;
}
.help-item{
  display: inline;
}
.help-top-padding{
  padding-top: 200px;
}
