@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/action-button.scss";
@import "./components/my-button.scss";
@import "./components/icon-button.scss";
@import "./components/occupation-stats.scss";
@import "./components/locker-list.scss";
@import "./components/occupation-bar.scss";
@import "./components/countdown-timer.scss";

@import "./views/request-locker.scss";
@import "./views/return-locker.scss";
@import "./views/occupation.scss";
@import "./views/home.scss";
@import "./views/processing.scss";
@import "./views/wrong-location.scss";
@import "./views/help.scss";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body{
  background-color: #00A2CE;
  font-family: 'Raleway', sans-serif;
  overflow: hidden;
  touch-action: none;
}
::-webkit-scrollbar {
    width: 20px;
}
::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: rgba(12, 177, 222, 0.3);
}
::-webkit-scrollbar-thumb {
    background-color: #00A2CE;
    border-radius: 5px;
}

.full-height{
  height: 76vh;
}
.my-shadow{
  -webkit-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.3);
}

.png-shadow{
  -webkit-filter: drop-shadow(3px 2px 2px rgba(0,0,0,.3));
  filter: drop-shadow(3px 2px 2px rgba(0,0,0,.3));
}

.available{
  background: linear-gradient(to bottom, #40CF9F 0%, #45DEAB 100%);
}
.occupied{
  background: linear-gradient(to bottom, #C7715B 0%, #E67E6A 100%);
}
.disabled{
  background: linear-gradient(to bottom, #414345 0%, #232526 100%);
}
.cleaning{
  background: linear-gradient(to bottom, #FFE27A 0%, #E8D964 100%);
}
.locker-status-0{
  background: linear-gradient(to bottom, #17E8DE 0%, #02B5FF 100%);
}
.locker-status-1{
  background: linear-gradient(to bottom, #e52d27 0%, #b31217 100%);
}
.bg-error{
  background-color: #fd7e14;
}
.bg-cuf-blue{
  background-color: #00A2CE;
}
.text-blue{
  color: #00A2CE;
}
.card-title{
  font-size: 1.5em;
  color: #2e2e2e;
}
.danger{
  background-color: #c91616;
  color: #fff;
}
.primary{
  background-color: #00A2CE;
  color: #fff;
}
