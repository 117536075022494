#countdown-timer {
  position: absolute;
  top: 250px;
  right: 150px;
  height: 200px;
  width: 200px;
  text-align: center;


  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  svg circle {
    stroke-dasharray: 615px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 10px;
    stroke: white;
    fill: none;
    animation: countdown 5s linear infinite forwards;
  }

}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 615px;
  }
}