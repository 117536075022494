@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.request-locker-wrapper{
  height: 90vh;
}
.request-locker-gif{
  height:500px;
  width: auto;
}
.request-locker-text{
  font-size: 2.5em;
  text-align: center;
  color: #fff;
}
.request-locker-number{
  font-family: 'Robot', sans-serif;
  line-height: 400px;
  font-size: 12em;
  text-align: center;
  color: #fff;
  display: inline-block;
}
.request-locker-number-container{
  border: 6px solid #fff;
  height: 400px;
  max-width: 500px;
  border-radius: 15px;
}
.locker-number-icon{
  width: 100px;
  margin-right: 40px;
  display: inline-block;
}
.door-warning{
  position: absolute;
  top: 200px;
  left: 10px;
  height: 250px;
  width: auto;
  animation: float 6s ease-in-out infinite;
}
@keyframes float {
	0% {
		filter: drop-shadow(5px 10px 5px rgba(0,0,0,0.6));
		transform: translatey(0px);
	}
	50% {
		filter: drop-shadow(5px 25px 5px rgba(0,0,0,0.3));
		transform: translatey(-10px);
	}
	100% {
		filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.6));
		transform: translatey(0px);
	}
}
