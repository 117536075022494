.spinner-wrapper{
  height: 100%;
}
.locker-list-card{
  margin-right: 200px;
  height: 700px;
  width: 100%;
  overflow: hidden;
}
.locker {
    height: 110px;
    width: 170px;
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    color: white;
    span {
        position: absolute;
        font-size: 1.4em;
        font-weight: bold;
        top: 5px;
    }
    span.number {
        right: 15px;
    }
    span.size {
        left: 15px;
    }
}
.locker-inner{
  color: #fff;
  background-color: #00A2CE;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}
.locker-logo-wrapper{
  margin-left: 0px;
  width: 100%;
  height: 100%;
}
.locker-logo{
  height: 30%;
  margin-top: 0px;
}
.locker-grid-wrapper{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: start;
  height: 600px;
  flex-direction: column;
  overflow-x: scroll;
}
.locker-status-indicator{
  margin-right: 25%;
  margin-left: 25%;
  height: 20px;
  width: 100%;
  border-radius: 5px;
}
