.spider-overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  pointer-events: none;
}
.spiderweb-overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  pointer-events: none;
}
.halloween-pumpkin{
  z-index: 998;
  position: absolute;
  top: 20px;
  left: 300px;
  height: 120px;
  width: auto;
}
.happy-halloween{
  z-index: 998;
  position: absolute;
  top: 25px;
  right: 300px;
  height: 150px;
  width: auto;
}
.halloween-ghost{
  z-index: 1;
  position: absolute;
  bottom: 130px;
  left: 100px;
  height: 350px;
  width: auto;
}
