.header-container{
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  height: 12vh;
}
.header-bar{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
}
.header-logo{
  padding-left: 20px;
  height: 80px;
}
.header-title{
  font-size: 2.6em;
  color: #275777;
}
.header-subtitle{
  font-size: 2.0em;
  color: #275777;
}
.header-datetime{
  padding-right: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5em;
  color: #275777;
}
