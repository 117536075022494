.wrong-location-wrapper{
  background-color: #ff660d;
}
.wrong-location-number-container{
  border: 6px solid #fff;
  height: 250px;
  max-width: 300px;
  border-radius: 15px;
}
.wrong-location-locker-number{
  font-family: 'Robot', sans-serif;
  line-height: 250px;
  font-size: 10em;
  text-align: center;
  color: #fff;
  display: inline-block;
}
.wrong-location-text-attention{
  font-size: 4em;
  text-align: center;
  color: #fff;
}
