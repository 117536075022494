.snow-overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  pointer-events: none;
}
.xmas-santa{
  z-index: 998;
  position: absolute;
  top: 25px;
  left: 300px;
  height: 150px;
  width: auto;
}
.xmas-lights{
  z-index: 998;
  position: absolute;
  top: 90px;
  left: 25px;
  width: 255px;
  height: auto;

}
.merry-xmas{
  z-index: 998;
  position: absolute;
  top: 45px;
  right: 300px;
  height: 100px;
  width: auto;
}
.xmas-snowman{
  z-index: 998;
  position: absolute;
  bottom: 150px;
  left: 100px;
  height: 170px;
  width: auto;
}
.xmas-tree1{
  z-index: 998;
  position: absolute;
  bottom: 115px;
  right: 300px;
  height: 180px;
  width: auto;
  pointer-events: none;
}
.xmas-tree2{
  z-index: 998;
  position: absolute;
  bottom: 140px;
  left: 400px;
  height: 190px;
  width: auto;
  pointer-events: none;
}
.xmas-tree3{
  z-index: 998;
  position: absolute;
  bottom: 135px;
  left: 600px;
  height: 130px;
  width: auto;
  pointer-events: none;
}
