.occupation-bar-wrapper {

  .occupation-bar-border {
    width: 400px;
    height: 10px;
    border: 1px solid;
    display: inline-block;
    margin-right: 20px;
  }

  .occupation-bar {
    height: 100%;
    transition: width 2s, background-color 2s;
  }

  .occupation-bar-text {
    font-size: 2em;
    text-align: center;
  }

  .occupation-bar-percentage {
    display: inline-block;
    height: 50px;
  }

  .occupation-bar-percentage p {
    position: absolute;
  }
}