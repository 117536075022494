.occupation-card{
  margin-left: 20px;
  height: 700px;
}
.stats{
  margin-top: 40px;
}
.occupation-stat{
  border-radius: 5px;
  padding: 0px;
  padding-right: 25px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 300px;
}
.occupation-stat-text{
  font-size: 1.5em;
  width: 300px;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  background-color: #00A2CE;
  height: 100%;
  padding: 20px;
  text-align:center;
  vertical-align: middle;
  display: table-cell;
  text-shadow: 3px 2px 3px rgba(0,0,0,.2);
}
