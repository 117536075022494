.footer-container{
  height: 9vh;
  width: 100vw;
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
.footer-container-expanded{
  transition: bottom 1s ease-in-out;
  bottom: 240px;
}
.footer-container-compacted{
  transition: bottom 1s ease-in-out;
  bottom: 30px;
}
.footer-bar{
  width: 100vw;
  position: absolute;
  bottom: -240px;
  left: 0px;
}
.footer-request-help-container{
  z-index: 2;
  padding-top: 50px;
}
.footer-request-help-buttons{
  margin-top: 40px;
}
.footer-row{
  height: 100%;
  margin: 0px;
}
.footer-text{
  font-size: 3em;
  color: #275777;
  height: 0;
  line-height: 0.7em;
}
.request-help-notification{
  height: 250px;
}
.request-help-button{
  position: absolute;
  right: 0px;
  top: -445px;
  height: 190px;
  width: 200px;
  background-color: #ffaa00;
  border-radius: 7px;
  border: 6px solid #fff;
  border-right: none;
  padding: 15px;
}
.request-help-button:active{
  transform: scale(1.1);
}
.call-assistance-text{
  color: #fff;
  font-weight: 600;
  font-size: 1.6em;
  text-align: center;
}
.call-assistance-icon{
  height: 80px;
  width: auto;
}
@keyframes appear{
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes disappear{
  0% {opacity: 1;}
  100% {opacity: 0;}
}
.appear{
  animation-name: appear;
  animation-duration: 1s;
}
.disappear{
  animation-name: disappear;
  animation-duration: 1s;
}
