.release-button{
  margin-bottom: 0px;
}
.info-timer{
  position: absolute;
  right: 100px;
  top: 0px;
  width: 350px;
  height: 200px;
}
.info-wrapper{
  background: linear-gradient(to bottom, #40CF9F 0%, #45DEAB 100%);
}
