.return-locker-wrapper{
  background-color: #e63333;
}
.return-locker-gif{
  height:500px;
  width: auto;
}
.return-locker-text{
  font-size: 2.5em;
  text-align: center;
  color: #fff;
}
.return-locker-text-big{
  font-size: 3em;
  text-align: center;
  color: #fff;
}
