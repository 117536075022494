.my-button{
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  height: 120px;
  min-width: 500px;
  margin-top: 30px;
  background-color: #fff;
  font-size: 2.5em;
  border: none;
  color: #275777;
  border-radius: 6px;
}
.my-button:active{
  transform: scale(1.1);
}
.my-button:focus{
  outline: none;
}
.my-button-disabled{
  color: rgba(39, 87, 119, 0.6)
}
.red{
  background-color: #c91616;
  color: #fff;
}
